 <template>
  <v-menu offset-y left v-model="menu" :close-on-content-click="false" @input="onMenuChanged">
    <template #activator="menu">
      <v-tooltip bottom :open-delay="300">
        <template #activator="tooltip">
          <div :class="customClass" v-on="{ ...menu.on, ...tooltip.on }">
            <slot name="button"></slot>
          </div>
        </template>
        <span class="text-no-wrap">{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <v-card :style="'width: ' + width">
      <v-card-title class="grey lighten-2 py-2 d-flex justify-space-between"> 
        <div>{{ title }} </div> 
        <v-btn icon small @click="menu=false" ><v-icon>mdi-close</v-icon> </v-btn>
      </v-card-title>
      <v-card-text class="pt-4">
        <slot name="body"></slot>

      </v-card-text>
    </v-card>
  </v-menu>
</template>
 <script>
export default {
  name: "CardMenu",
  data: () => ({
    menu: false,
  }),
  props: {
    title: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    width:{
      type: String,
      default: "800px",
    },
    value:{
      type: Boolean,
      default: false
    }
  },
  watch:{
    value(){
      // console.log('watch',this.value)
      this.menu = this.value
    }
  },
  methods: {
    onMenuChanged(val){
        this.$emit('input',val)
    },
    setData(data) {
      this.data = data; // Store additional data
    },
  },
};
</script>